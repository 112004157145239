import { useState } from "react";
import Avatar from "./Avatar";
import {
    Box,
    CircularProgress,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from "@material-ui/core";

import useCaseFiles from "../hooks/queries/useCaseFiles";
import { format } from "date-fns";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getComparator, stableSort } from "../utils/tableUtils";
import caseDocumentTypes from "../constants/caseDocumentTypes";
import documentService from "../services/documentService";
import { SortOrder } from "../utils/sortOrder";
import { useSnackbar } from "notistack";
import caseService from "../services/caseService";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
    },
    profilePhoto: {
        marginRight: theme.spacing(2),
    },
}));

const tableHeadCells = [
    {
        id: "name",
        label: "Name",
        isSortable: true,
    },
    {
        id: "dateAdded",
        label: "Date Added",
        isSortable: true,
    },
    {
        id: "createdBy",
        label: "Created By",
    },
    {
        id: "fileType",
        label: "File Type",
    },
];

function CaseFilesCard({ caseId, setDocumentGuid, openFileViewer }) {
    const classes = useStyles();
    const { data, isLoading, isError, error } = useCaseFiles(caseId, false);
    const [order, setOrder] = useState<SortOrder>("asc");
    const [orderBy, setOrderBy] = useState("dateAdded");

    const { enqueueSnackbar } = useSnackbar();

    const onSortClick = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const onDocumentViewClick = async (file) => {
        if (file.type === caseDocumentTypes.PRECEDENT_DOCUMENT) {
            handlePrecedentFilePreview(
                file.precedentFileMetadata.precedentIdentifier
            );
            return;
        }
        await handleAzureFilePreview({
            documentName: file.name,
            reference: file.azureFileMetadata.azureFileReference,
            contentType: file.azureFileMetadata.contentType,
            extension: file.extension,
        });
    };

    const handlePrecedentFilePreview = (identifier) => {
        setDocumentGuid(identifier);
    };

    const handleAzureFilePreview = async ({
        documentName,
        reference,
        contentType,
        extension,
    }) => {
        try {
            const previewPath = await documentService.retrieveDocumentPreviewPath({
                reference,
                contentType,
                filename: documentName
            });

            if (previewPath === null) {
                enqueueSnackbar("File may be password protected - downloading instead", { variant: "warning" });

                const response = await caseService.fetchDocument(reference, "arraybuffer");
                let blob = new Blob([response], { type: extension });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = documentName;
                link.click();
                return;
            }

            const attachment = {
                reference,
                filename: documentName,
                contentType,
            };

            openFileViewer(
                {
                    type: extension,
                    path: previewPath,
                    name: documentName,
                    azureGuid: reference,
                },
                null,
                attachment
            );
        } catch (e) {
            console.error(e);
        }
    };

    const tableContent = () => {
        if (isLoading)
            return (
                <TableRow>
                    <TableCell colSpan={5}>
                        <Box display="flex" justifyContent="center">
                            <CircularProgress size={24} />
                        </Box>
                    </TableCell>
                </TableRow>
            );

        if (isError)
            return (
                <TableRow>
                    <TableCell colSpan={5}>
                        <Box display="flex" justifyContent="center">
                            <Typography>
                                {error?.message || "Could not fetch document files for case."}
                            </Typography>
                        </Box>
                    </TableCell>
                </TableRow>
            );

        if (data && data.length > 0)
            return stableSort(data, getComparator(order, orderBy)).map(
                (file, index) => (
                    <TableRow key={index}>
                        <TableCell>{file.name}</TableCell>
                        <TableCell>
                            {format(new Date(file.dateAdded), "dd/MM/yyyy - HH:mm")}
                        </TableCell>
                        <TableCell>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                            >
                                <Avatar
                                    className={classes.profilePhoto}
                                    size="100px"
                                    alt={file.createdBy.name}
                                />
                                <Typography variant="body2">{file.createdBy.name}</Typography>
                            </Box>
                        </TableCell>
                        <TableCell>{file.extension}</TableCell>
                        <TableCell align="right">
                            <IconButton onClick={() => onDocumentViewClick(file)}>
                                <VisibilityIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            );

        return (
            <TableRow>
                <TableCell colSpan={5}>
                    <Box display="flex" justifyContent="center">
                        <Typography>No associated documents found.</Typography>
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Paper className={classes.card}>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableHeadCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                sortDirection={
                                    headCell.isSortable && orderBy === headCell.id ? order : false
                                }
                            >
                                {headCell.isSortable ? (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={() => onSortClick(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </TableCell>
                        ))}
                        <TableCell align="right">View</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{tableContent()}</TableBody>
            </Table>
        </Paper>
    );
}

export default CaseFilesCard;