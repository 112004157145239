import axios from "../utils/axios";
import { buildQueryStringList } from "../utils/stringUtils";
import { Upload } from "../contexts/fileUploadContext";
import { MiscActivityCategory } from "../constants/miscActivityCategory";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

export type Attachment = {
    reference: string;
    filename: string;
    contentType: string;
    contentId: string;
    azureFileId: number;
    extension: string;
};
export type Precedent = {
    precedentId: number;
    name: string;
    sectorId?: number;
    caseTypeId: number;
    currentVersion: number;
    htmlContent: string;
    partnershipId?: number;
    azureFileId?: number;
    azureFileContentType: string;
    azureFileReference?: string;
    precedentFolderId?: number;
    disabled: boolean;
    sortIndex?: number;
    precedentVersionId?: number;
};

export type ActivityEvent = {
    caseActivityId: number;
    adviserId: number;
    adviserExternalId: string;
    adviserName: string;
    adviserPhoto?: string;
    itemType: string;
    eventTime: Date;
    content: string;
    direction: number; //enum
    person: string;
    contactIds: number[];
    attachments: Attachment[];
    precedents: Precedent[];
    subject: string;
    toAddress: string;
    fromAddress: string;
    duration?: number;
    caseId: number;
    recordingUrl: string;
    hideCallRecording: boolean;
    callDuration?: number;
    issue: string;
    advice: string;
    action: string;
    htmlNote: string;
    text: string;
    caller: string;
    phone: string;
    ccAddresses: string;
    bccAddresses: string;
    replyingToEmailId?: number;
    activityType?: number; //enum
    miscActivityTypeId?: number;
    miscActivityTypeDescription: string;
    miscActivityCategory?: MiscActivityCategory;
    sentFromEmailDraftId?: number;
    graphMessageId: string;
    isChargeable: boolean;
    isExcluded: boolean;
    activityDescription: string;
    hasPrecedents: boolean;
    isLocked: boolean;
    unitisedSeconds?: number;
    unitisedMinutes?: number;
    units?: number;
    isSyncing?: boolean;
};

export type FetchCaseActivitiesResponse = {
    caseActivities: ActivityEvent[];
    photos: {}
    
};
export type YouManageEmployee = {
    id: number;
    youManageExternalId: string;
    workEmail: string;
    personalEmail: string;
    firstName: string;
    lastName: string;
    workTelephone: string;
    personalTelephone: string;
    address: string;
    leavingDate?: Date;
    department: string;
    manager: string;
};

export type CaseEmployee = {
    employeeId: number;
    name: string;
    position: string;
    protectedCharacteristics: string;
    employmentStartDate?: Date;
    linkedCasesCount: number;
    isStartEstimate?: boolean;
    isLeaver?: boolean;
    dateOfBirth?: Date;
    notes: string;
    youManageEmployee: YouManageEmployee;
};

export type CaseContact = {
    id: number;
    name: string;
    isPrimary: boolean;
    position: string;
};

export type CaseExternalContact = {
    externalContactId?: number;
    name: string;
    contactType: string;
};

export type CaseAccount = {
    name: string;
    address: string;
    sector: string;
    serviceType: string;
    clientManager: string;
    contractEnd: Date;
    status: string;
    isDeleted: boolean;
};

export type CaseAdviser = {
    id: number;
    name: string;
    isPrimary: boolean;
    position: string;
    photo: string;
};

export type CaseDocument = {
    id: number;
    identifier: string;
    name: string;
};

export type CaseEvidenceFiles = {
    reference: string;
    filename: string;
    contentType: string;
    uploadedByName: string;
    uploadedByPosition: string;
    uploadedOn: Date;
};

export type CaseStage = {
    id: number;
    name: string;
    isActive: boolean;
    isComplete: boolean;
};

export type CaseTask = {
    id: number;
    name: string;
    isComplete: boolean;
};

export type FetchCaseDetailsResponse = {
    id: number;
    caseType: string;
    caseStage: string;
    createdBy: string;
    contract: string;
    contractId: number;
    dateCreated: Date;
    dateClosed?: Date;
    eventCount: number;
    isVisibleToContactsOnly: boolean;
    isET: boolean;
    isUnitisedTime: boolean;
    totalActivityTime?: number;
    isChargeable: boolean;
    totalChargeableActivityTimeInSeconds?: number;
    budgetedMinutes?: number;
    caseOutcome: string;
    description: string;
    employees: CaseEmployee[];
    contacts: CaseContact[];
    externalContacts: CaseExternalContact[];
    account: CaseAccount;
    advisers: CaseAdviser[];
    documents: CaseDocument[];
    evidenceFiles: CaseEvidenceFiles[];
    stages: CaseStage[];
    tasks: CaseTask[];
};

export type FetchCaseTimeResponse = {
    caseId: number;
    isUnitisedTime: boolean;
    isChargeable: boolean;
    budgetedSeconds: number;
    budgetedMinutes: number;
    budgetedHours: number;
    chargeableTime: number;
    nonChargeableTime: number;
    totalActivityTime: number;
    chargeableTimeUsed?: number;
    remainingBudgetedMinutes?: number;
};

export type ETCaseClaim = {
    claimId: number;
    claimTypeName: string;
    isInsured: boolean;
    successPercentage: number;
};

export type FetchETCaseDetailsResponse = {
    id: number;
    caseId: number;
    caseNum: string;
    earlyConciliationNumber: string;
    venueId?: number;
    subVenue: string;
    insurerName: string;
    isCaseCovered?: boolean;
    insuranceReference: string;
    hoursAuthorised?: number;
    notes: string;
    estimatedCouncilFees?: number;
    actualCouncilFees?: number;
    estimatedWorstCaseAward?: number;
    estimatedLikelyAward?: number;
    actualAward?: number;
    recommendedSettlementRangeFrom?: number;
    recommendedSettlementRangeTo?: number;
    actualSettlement?: number;
    caseOutcome: string;
    claims: ETCaseClaim[];
};

export type Enum = {
    [id: number]: string;
};

export type CaseActionReminder = {
    id?: number;
    due?: Date;
    amt: number;
    metric: string;
    taskSid: string;
    metricTag: Enum;
};
export type CaseActionAdviser = {
    id?: number;
    userId: number;
    name: string;
    email: string;
    label: string;
};

export type FetchOpenAndEtCaseActionsResponse = {
    id?: number;
    typeId: number;
    type: string;
    summary: string;
    important: boolean;
    due: Date;
    details: string;
    completedOn?: Date;
    completedById?: number;
    completedBy: string;
    caseId: number;
    adviserId?: number;
    teamId?: number;
    assignedTo: string;
    categoryId: number;
    category: string;
    createdById: number;
    isET: boolean;
    taskSid: string;
    reminders: CaseActionReminder[];
    advisers: CaseActionAdviser[];
};

const fetchContactCasesForHeadlines = (
    fromDate,
    toDate,
    displayAll,
    externalIds
) =>
    axios.get<never, any>(
        apiUrl +
        `/client/getclientcontactcasesforheadlines${buildQueryStringList(
            "externalIds",
            externalIds
        )}`,
        {
            params: {
                fromDate,
                toDate,
                displayAll,
            },
        }
    );

const fetchCasesForContact = (
    caseNumberQuery,
    adviserQuery,
    employeeQuery,
    descriptionQuery,
    pageSize,
    pageNum,
    primaryContactIdQuery,
    displayAll,
    showClosed,
    siteExternalIds,
    caseType
) => {
    let queryString = buildQueryStringList("externalIds", siteExternalIds);
    queryString += queryString ? "&" : "?";
    if (caseNumberQuery) queryString += `&caseNumberQuery=${caseNumberQuery}`;
    if (adviserQuery) queryString += `&adviserQuery=${adviserQuery}`;
    if (employeeQuery) queryString += `&employeeQuery=${employeeQuery}`;
    if (descriptionQuery) queryString += `&descriptionQuery=${descriptionQuery}`;
    if (primaryContactIdQuery)
        queryString += `&primaryContactIdQuery=${primaryContactIdQuery}`;
    if (displayAll) queryString += `&displayAll=${displayAll}`;
    if (showClosed) queryString += `&showClosed=${showClosed}`;
    queryString += `&pageSize=${pageSize}&pageNum=${pageNum}`;

    if (caseType) queryString += `&caseTypeId=${caseType}`;

    return axios.get<never, any>(
        `${apiUrl}/client/getclientcontactscases${queryString}`
    );
};

const fetchCaseDetails = (caseId) =>
    axios.get<never, FetchCaseDetailsResponse>(
        apiUrl + "/client/getcasedetails/",
        {
            params: {
                caseId: caseId,
            },
        }
    );

const fetchCaseActivities = (caseId) => {
    return axios.get<never, FetchCaseActivitiesResponse>(
        apiUrl + "/client/getcaseactivities/",
        {
            params: {
                caseId
            },
        }
    );
};

const fetchCaseActivityEmail = (caseId, caseActivityId) => {
    return axios.get<never, ActivityEvent>(
        apiUrl + "/client/getcaseactivityemail/",
        {
            params: {
                caseId,
                caseActivityId
            },
        }
    );
};


const fetchInitialData = async () => {
    const data = await axios.get<never, any>(apiUrl + "/client/getinitialdata/");

    const newCaseTypes = data.caseTypes.reduce(
        (prev, curr) => ({ ...prev, [curr.caseTypeId]: { ...curr } }),
        {}
    );
    const newClaimTypes = data.claimTypes.reduce(
        (prev, curr) => ({ ...prev, [curr.claimTypeId]: { ...curr } }),
        {}
    );
    const newPartnerships = data.partnerships.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: { ...curr } }),
        {}
    );
    const newPrecedentFolders = data.precedentFolders.reduce(
        (prev, curr) => ({ ...prev, [curr.precedentFolderId]: { ...curr } }),
        {}
    );
    const newPrecedents = data.precedents.reduce(
        (prev, curr) => ({ ...prev, [curr.precedentId]: { ...curr } }),
        {}
    );
    const newVenues = data.venues.reduce(
        (prev, curr) => ({ ...prev, [curr.venueId]: { ...curr } }),
        {}
    );

    data.caseTypes = newCaseTypes;
    data.claimTypes = newClaimTypes;
    data.partnerships = newPartnerships;
    data.precedentFolders = newPrecedentFolders;
    data.precedents = newPrecedents;
    data.venues = newVenues;

    return data;
};

const fetchDocumentVersions = (documentGuid) =>
    axios.get<never, any>(`${apiUrl}/client/getdocumentversions/${documentGuid}`);

const fetchDocument = (fileReference, contentType) =>
    axios.get<never, any>(`${apiUrl}/client/downloaddocument/${fileReference}`, {
        responseType: contentType.startsWith("video") ? "stream" : "arraybuffer",
    });

const fetchCaseFiles = async (caseId, downloadOnly) =>
    axios.get<never, any>(`${apiUrl}/client/getdocumentsforcasedocumentviewer/${caseId}/${downloadOnly}`);

const fetchTimeSummary = (fromDate, toDate, displayAll, accountExternalIds) =>
    axios.get<never, any>(
        `${apiUrl}/client/gettimesummary/${buildQueryStringList("externalIds", accountExternalIds)}`,
        {
            params: {
                fromDate: fromDate,
                toDate: toDate,
                displayAll: displayAll,
            },
        }
    );

const fetchDashboardCases = (
    pageSize,
    pageNum,
    fromDate,
    toDate,
    displayAll,
    accountExternalIds
) =>
    axios.get<never, any>(
        `${apiUrl}/getdashboardcases/${buildQueryStringList("externalIds", accountExternalIds)}`,
        {
            params: {
                pageSize: pageSize,
                pageNum: pageNum,
                fromDate: fromDate,
                toDate: toDate,
                displayAll: displayAll,
            },
        }
    );

const fetchCaseTime = (caseId) =>
    axios.get<never, FetchCaseTimeResponse>(`${apiUrl}/client/casetime/${caseId}`);

const fetchETCaseDetails = (caseId) =>
    axios.get<never, FetchETCaseDetailsResponse>(`${apiUrl}/client/getetcase/${caseId}`);

const fetchOpenAndETCaseActions = (caseId) =>
    axios.get<never, FetchOpenAndEtCaseActionsResponse[]>(`${apiUrl}/client/getopenandetcaseactions/${caseId}`);

export type AddCustomerNoteParams = {
    caseId: number;
    subject: string;
    message: string;
    attachments: Upload[]
}

const addCustomerNote = ({ caseId, subject, message, attachments }: AddCustomerNoteParams) => {
    let formData = new FormData();

    formData.append("caseId", caseId.toString());
    formData.append("subject", subject);
    formData.append("notes", message);

    formData.append("stringifiedFiles", JSON.stringify(attachments.map(file => ({ reference: file.id, name: file.file?.name, type: file.file?.type, size: file.file?.size }))));

    return axios.post<never, number>(apiUrl + `/client/addcustomernote`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
};

export type UpdateCustomerNoteParams = {
    caseId: number,
    caseActivityId: number;
    subject: string;
    message: string;
    newAttachments?: Upload[];
    filesToDelete?: string[];
    pageNumber: number;
}

const updateCustomerNote = ({ caseId, caseActivityId, subject, message, newAttachments, filesToDelete }: UpdateCustomerNoteParams) => {
    let formData = new FormData();

    formData.append("caseId", caseId.toString());
    formData.append("caseActivityId", caseActivityId.toString());
    formData.append("subject", subject);
    formData.append("notes", message);

    formData.append("stringifiedFiles", JSON.stringify(newAttachments?.map(file => ({ reference: file.id, name: file.file?.name, type: file.file?.type, size: file.file?.size }))));
    filesToDelete?.forEach((file) => formData.append("filesToDelete", file));

    return axios.patch<never, never>(apiUrl + "/client/updatecustomernote", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}

export type SeekAdviceParams = {
    caseId?: number;
    subject: string;
    message: string;
    attachments?: Upload[];
    adviserIds: number[];
    adviserId: number | null;
    important: boolean;
}

const seekAdvice = ({ caseId, subject, message, attachments, adviserIds, adviserId, important }: SeekAdviceParams) => {
    const stringifiedFiles = JSON.stringify(attachments?.map(file => ({ reference: file.id, name: file.file?.name, type: file.file?.type, size: file.file?.size })));
    return axios.post<never, number>(`${apiUrl}/client/seekadvice`, { caseId, subject, message, stringifiedFiles, adviserId, adviserIds, important });
}

export type RaiseCaseRequestParams = {
    subject: string;
    message: string;
    employee?: string;
    attachments?: Upload[];
    siteExternalId: string;
    siteExternalIds: string[];
}

const addRaiseCaseRequest = ({ subject, message, employee, attachments, siteExternalId, siteExternalIds }: RaiseCaseRequestParams) => {
    const stringifiedFiles = JSON.stringify(attachments?.map(file => ({ reference: file.id, name: file.file?.name, type: file.file?.type, size: file.file?.size })));
    return axios.post<never, number>(`${apiUrl}/client/raisecaserequest`, { subject, message, employee, stringifiedFiles, siteExternalId, siteExternalIds });
}

const caseService = {
    fetchCasesForContact,
    fetchCaseDetails,
    fetchInitialData,
    fetchDocumentVersions,
    fetchDocument,
    fetchCaseActivities,
    fetchCaseFiles,
    fetchContactCasesForHeadlines,
    fetchTimeSummary,
    fetchDashboardCases,
    fetchCaseTime,
    fetchETCaseDetails,
    fetchOpenAndETCaseActions,
    addCustomerNote,
    updateCustomerNote,
    seekAdvice,
    addRaiseCaseRequest,
    fetchCaseActivityEmail,
};

export default caseService;