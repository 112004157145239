import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import WysiwygEditor from "./WysiwygEditor";
import caseService from "../services/caseService";
import editorTypes from "../constants/editorTypes";

import { format } from "date-fns";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Chip,
  Button,
} from "@material-ui/core";
import { GetApp as DownloadIcon } from "@material-ui/icons";

const useStyles = makeStyles({
  dialog: {
    height: "calc(100% - 64px)",
  },
  dialogBox: {
    height: "100%",
  },
  dialogContentBox: {
    height: "100%",
    overflow: "hidden",
  },
});

const DocumentDialog = ({ open, onClose, documentGuid }) => {
  const classes = useStyles();
  const [versions, setVersions] = useState([]);
  const [current, setCurrent] = useState<any>(null);

  const [editorState, setEditorState] = useState();
  const [content, setContent] = useState();

  useEffect(() => {
    const getVersions = async () => {
      const result = await caseService.fetchDocumentVersions(documentGuid);
      result && setVersions(result);
      result && setCurrent(result[result.length - 1]);
    };
    if (documentGuid) {
      getVersions();
    }
  }, [documentGuid]);

  useEffect(() => {
    if (!current) return;
    const html = current.htmlContent || "";
    setContent(html);
  }, [current]);

  const downloadPdf = async () => {
    const response = await caseService.fetchDocument(
      current.file.reference,
      current.file.contentType
    );

    let blob = new Blob([response], { type: current.file.contentType });
    let link = window.document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = current.file.filename;
    link.click();
  };

  const setEditorData = () => {
    if (!current || !editorState) return;
    const html = current.htmlContent || "";
    setEditorState(html);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      classes={{ paper: classes.dialog }}
    >
      <Box display="flex" flexDirection="column" className={classes.dialogBox}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>{current && current.name}</DialogTitle>
          {current && current.file && (
            <Button onClick={downloadPdf}>
              <DownloadIcon fontSize="large" />
            </Button>
          )}
        </Box>
        <Box flexGrow={1}>
          <DialogContent className={classes.dialogBox}>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.dialogContentBox}
            >
              {current && (
                <Box>
                  {current.createdBy} -{" "}
                  {format(new Date(current.createdOn), "dd/MM/yyyy")}
                </Box>
              )}
              <Box display="flex" flexWrap="wrap" alignItems="center">
                <Box m={2}>Versions</Box>
                {versions &&
                  versions.map((v: any) => (
                    <Box m={1} key={v.currentVersion}>
                      <Chip
                        label={v.currentVersion}
                        onClick={() => setCurrent(v)}
                        color={v === current ? "primary" : "default"}
                        variant={v.file ? "default" : "outlined"}
                      />
                    </Box>
                  ))}
              </Box>
              <Box>
                <WysiwygEditor
                  content={content}
                  onChange={setEditorData}
                  type={editorTypes.READONLY}
                />
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocumentDialog;
